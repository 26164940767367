@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "./Variables.scss";
@import "./Animations.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url("./Assets/Fonts/verdana.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "VerdanaB";
  src: local("Verdana"), url("./Assets/Fonts/verdanab.ttf") format("truetype"); /* IE6-IE8 */
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url("./Assets/Fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("./Assets/Fonts/Poppins-Bold.ttf") format("truetype"); /* IE6-IE8 */
  font-display: swap;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

* {
  user-select: none;
}

body {
  overflow-x: hidden;
}

.content {
  flex: 1;
}

h1,
h2,
h3,
h5 {
  font-family: "Poppins-Bold", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
}

h1 {
  font-size: 1.6rem;
  @media screen and (max-width: 420px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: 922px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 1.2rem;
  @media screen and (min-width: 576px) {
    font-size: 1.4rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.3rem;
  }
  @media screen and (min-width: 922px) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.6rem;
  }
}
h3 {
  font-size: 1rem;
  @media screen and (min-width: 576px) {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.1rem;
  }
  @media screen and (min-width: 922px) {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}
h5 {
  font-size: 1.1rem;
  @media screen and (min-width: 576px) {
    font-size: 1.3rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 922px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
}

p {
  font-family: "Verdana", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: var(--primary-grey);
}

.container-xxl {
  max-width: 1560px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container-ac {
  padding: 0 1rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 420px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 576px) {
    padding: 0 3.5rem;
  }

  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }

  @media screen and (min-width: 922px) {
    padding: 0 7rem;
  }
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

hr {
  border-top: 2px solid var(--primary-orange);
  margin-top: 7px;
  margin-bottom: 1rem;
  width: 35px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
}

.row-pad {
  padding: 6rem 0;
}

.pad-top {
  padding-top: 6rem !important;
}

.bgGrey {
  background-color: #f7f7f7;
}

.bgWhite {
  background-color: white !important;
}

.not-found {
  height: calc(100vh - 78px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  h1,
  h2 {
    color: var(--primary-orange);
  }
  h1 {
    font-size: 4rem;
  }
}

.legal-docs {
  p {
    color: #000;
  }
}

.button:focus {
  outline: none !important;
}
